<template>
  <div class="flex-1">
    <h1>Campaign Reports</h1>
    <table>
      <thead>
        <tr>
          <th>Campaign</th>
          <th>Start Date</th>
          <th>Status</th>
          <th>Campaign Channel</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="campaignReport in campaignsReports" :key="campaignReport.id">
          <td>{{ campaignReport.campaign.title }}</td>
          <td>{{ campaignReport.created_at }}</td>
          <td>{{ campaignReport.status ? 'Ready' : 'Generating' }}</td>
          <td>{{ campaignReport.campaign.campaign_type }}</td>
          <td>
            <omfy-button
              v-if="campaignReport.status"
              @click="downloadReport(campaignReport.id)"
              variant="bg-blue-700 hover:bg-blue-800"
              icon="document"
              size="small"
            >
              Download
            </omfy-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isLoading: 'campaignsReports/getIsLoading',
      labelList: 'labels/getLabels',
      campaignsReports: 'campaignsReports/getAllCampaignsReports',
    }),
    showEmptyResult() {
      const hasEmptyResults =
        !this.isLoading && this.campaignsReports.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    async downloadReport(id) {
      this.$store.dispatch('campaignsReports/downloadReport', id);
    },
    viewData() {
      // Implement view data functionality here
    },
  },
  created() {
    this.$store.dispatch('campaignsReports/getCampaignsReportsRequest');
  },
};
</script>
