import CampaignsReportsAPI from '../../api/campaignsReports';

export const state = {
  records: [],
  isLoading: false,
};

export const getters = {
  getIsLoading(_state) {
    return _state.isLoading;
  },
  getAllCampaignsReports: _state => {
    return _state.records;
  },
};

export const actions = {
  getCampaignsReportsRequest: async function ({ commit }) {
    commit('SET_IS_LOADING', { isLoading: true });
    try {
      const response = await CampaignsReportsAPI.get();
      commit('SET_CAMPAIGN_REPORTS', response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit('SET_IS_LOADING', { isLoading: false });
    }
  },

  downloadReport: async function ({ commit }, id) {
    commit('SET_IS_LOADING', { isLoading: true });
    try {
      const response = await CampaignsReportsAPI.reportDownload(id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'campaign_report.csv'); // ou o nome do arquivo que você deseja
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
      // Ignore error
    } finally {
      commit('SET_IS_LOADING', { isLoading: false });
    }
  },
};

export const mutations = {
  SET_IS_LOADING(_state, payload) {
    _state.isLoading = payload.isLoading;
  },
  SET_CAMPAIGN_REPORTS(_state, payload) {
    _state.records = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
